import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./users.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

import {
	Box,
	Button,
	Container,
	Input,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
function AllEmployee() {
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	const [users, setUser] = useState(null);
	const [load, setLoad] = useState(false);
	const [searched, setSearched] = useState("");
	const navigate = useNavigate();
	axios.interceptors.request.use(
		(config) => {
			const token = sessionStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);

	useEffect(() => {
		if (load == false) {
			axios
				.get(`${process.env.REACT_APP_API_URL}/auth/getEmployee`, {})
				.then((res) => {
					localStorage.setItem("data", JSON.stringify(res.data.data));
					setUser(res.data.data);
					setLoad(true);
				})
				.then((res) => setLoad(true));
		}
	}, [load]);
	const requestSearch = (searchedVal) => {
		setSearched(searchedVal);

		if (searchedVal !== "") {
			const filteredRows = JSON.parse(localStorage.getItem("data")).filter(
				(row) => {
					return row.username.toLowerCase().includes(searchedVal.toLowerCase());
				}
			);
			setUser(filteredRows);
		} else {
			setUser(JSON.parse(localStorage.getItem("data")));
		}
	};
	if (users !== null) {
		return (
			<>
				<ThemeProvider theme={theme}>
					<DashboardLayout>
						{
							<Box
								component="main"
								sx={{
									flexGrow: 1,
									py: 1,
								}}
							>
								<Container maxWidth={false}>
									<div className="container_set">Danh sách nhân viên</div>
									<div className="form_set">
										<Box sx={{ minWidth: 600 }}>
											<TextField
												value={searched}
												onChange={(searchVal) =>
													requestSearch(searchVal.target.value)
												}
												placeholder="Tìm kiếm"
												sx={{ marginBottom: "5px", paddingRight: "700px" }}
											/>
											<Table sx={{ width: 1600 }}>
												<TableHead>
													<TableRow>
														<TableCell>ID User</TableCell>
														<TableCell>Username</TableCell>
														<TableCell>Mã giới thiệu</TableCell>
														<TableCell>Ngày tạo</TableCell>
														{/* <TableCell>Admin Cộng</TableCell>

														<TableCell>Admin Trừ</TableCell>
														<TableCell>Admin Thưởng</TableCell>
														<TableCell>Tổng đặt</TableCell>
														<TableCell>Rút/ Nạp Tiền</TableCell>
														<TableCell>Thưởng</TableCell> */}
														<TableCell>Xem thông tin</TableCell>
													</TableRow>
												</TableHead>
												{users ? (
													<TableBody>
														{users.map((item) => (
															<>
																<TableRow>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item?.iduser}
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item.username}
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item.code}
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{formatDate(new Date(item?.createdAt))}
																	</TableCell>
																	{/* <TableCell sx={{ fontWeight: "600" }}>
																	{item.adminadd.toLocaleString()}
																</TableCell> */}
																	{/* <TableCell sx={{ fontWeight: "600" }}>
																	{item.admintru.toLocaleString()}
																</TableCell>
																<TableCell sx={{ fontWeight: "600" }}>
																	{item.adminthuong.toLocaleString()}
																</TableCell>
																<TableCell sx={{ fontWeight: "600" }}>
																	{item.totalbet.toLocaleString()}
																</TableCell> */}
																	{/* <TableCell sx={{ fontWeight: "600" }}>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			const dataForm = {
																				id: item._id,
																				money: e.target.money.value,
																			};
																			axios
																				.post(
																					`${process.env.REACT_APP_API_URL}/auth/update`,
																					dataForm
																				)
																				.then((res) => {
																					setUser(res.data.data);
																					setLoad(false);
																					swal("Cộng/Trừ tiền thành công!")
																				});
																		}}
																	>
																		<input
																			style={{ width: "100px" }}
																			id={item._id}
																			name="money"
																			type="number"
																		/>
																		<Button type="submit">Xác nhận</Button>
																	</form>
																</TableCell> */}
																	{/* <TableCell sx={{ fontWeight: "600" }}>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			const dataForm = {
																				id: item._id,
																				money: e.target.money.value,
																			};
																			axios
																				.post(
																					`${process.env.REACT_APP_API_URL}/auth/adminthuong`,
																					dataForm
																				)
																				.then((res) => {
																					
																					setLoad(false);
																					swal("Thưởng thành công!")
																				});
																		}}
																	>
																		<input
																			style={{ width: "100px" }}
																			id={item._id}
																			name="money"
																			type="number"
																		/>
																		<Button type="submit">Xác nhận</Button>
																	</form>
																</TableCell> */}
																	<TableCell
																		sx={{ fontWeight: "600", display: "flex" }}
																	>
																		{item.isLock == false ? (
																			<Button
																				onClick={() => {
																					axios
																						.post(
																							`${process.env.REACT_APP_API_URL}/auth/lockkey`,
																							{
																								id: item._id,

																								isLock: true,
																							}
																						)
																						.then((res) => {
																							setLoad(false);
																						});
																				}}
																			>
																				Khóa
																			</Button>
																		) : (
																			<Button
																				onClick={() => {
																					axios
																						.post(
																							`${process.env.REACT_APP_API_URL}/auth/lockkey`,
																							{
																								id: item._id,

																								isLock: false,
																							}
																						)
																						.then((res) => {
																							setLoad(false);
																						});
																				}}
																			>
																				Mở
																			</Button>
																		)}
																		<Button
																			onClick={() =>
																				navigate(`/admin/employee/${item.code}`)
																			}
																		>
																			Xem thành viên
																		</Button>
																		<Button
																			onClick={() =>
																				navigate(`/admin/employee/${item._id}`)
																			}
																		>
																			Xem thông tin
																		</Button>
																	</TableCell>
																</TableRow>
															</>
														))}
													</TableBody>
												) : null}
											</Table>
										</Box>
									</div>
								</Container>
							</Box>
						}
					</DashboardLayout>
				</ThemeProvider>
			</>
		);
	} else {
		return <></>;
	}
}
export default AllEmployee;
